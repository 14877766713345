<template>
    <div class="qr-container">
        <div ref="qrPDF" class="qr" v-click-outside="close">
            <span style="font-size: 2rem; font-weight: bold">Remote Support</span>
            <div class="span" style="width: 200px; height: 200px">
                <img :src="'data:image/jpeg;base64,' + info.barcode" style="width: 100%; height: 100%">
            </div>
            <div class="info" style="display: flex; flex-direction: column; gap: 10px; align-items: center; font-weight: 500; font-size: 1.5rem">
                <span> {{info.name}}</span>
                <span> {{info.serial_no}}</span>
            </div>
        </div>
        <div class="download">
            <span @click="openQR">download</span>
        </div>
    </div>
</template>

<script>
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
export default {
    props: {
        info: {
            required: false,
            type: Object,
        },
    },
    methods: {
        openQR() {
            html2canvas(this.$refs.qrPDF).then((canvas) => {
                var doc = new jsPDF('p', 'px', 'a4');
                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();

                const widthRatio = pageWidth / canvas.width;
                const heightRatio = pageHeight / canvas.height;
                const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

                const canvasWidth = canvas.width * ratio;
                const canvasHeight = canvas.height * ratio;

                const marginX = (pageWidth - canvasWidth) / 2;
                doc.addImage(canvas, 'JPEG', marginX, 0.1, canvasWidth, canvasHeight);
                doc.save(`${this.info.name}.pdf`);
            });
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
    .qr-container {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 4;
        .close {
            position: absolute;
        }
        .qr {
            width: 400px;
            background: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            padding: 40px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            box-shadow: 0px 1px 6px #CAD6E280;
        }
        .download {
            width: 400px;
            background: white;
            padding: 0 0 40px;
            display: flex;
            justify-content: center;
            text-transform: capitalize;
            span {
                background: var(--primary-color);
                color: white;
                padding: 15px 50px;
                border-radius: 5px;
                cursor: pointer;
                user-select: none;
                transition: 0.5s;
                &:hover {
                    background: #2155cf;
                }
            }
        }
    }
</style>
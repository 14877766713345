<template>
  <div class="faq" :class="{ details: show }">
    <div ref="tcktinfo" class="info">
      <div class="item" @click="details = !details">{{ faq.title }}</div>
      <div class="item" @click="details = !details">
        <img src="/assets/images/down_arrow_blue.svg" />
      </div>
    </div>
    <div
      class="details"
      ref="details"
      :class="{ open: details, close: !details }"
      v-if="show"
    >
      <span class="title">Answer:</span>
      <span v-html="faq.description.replace(/\\/g, '')"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faq: {
      type: Object,
    },
  },

  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      edit: false,
      details: false,
      open: false,
      show: false,
      editFaq: false,
    };
  },

  watch: {
    details(val) {
      if (val) this.show = true;
      else {
        setTimeout(() => {
          this.show = false;
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  background: white;
  border-bottom: solid 1px #f2f5f8;
  cursor: pointer;

  &.details {
    .info {
      .item {
        &:nth-last-child(2) {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .info {
    width: 98%;
    height: 80px;
    display: flex;
    .item {
      width: 93%;
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      user-select: none;
      &:nth-last-child(1) {
        width: 5%;
        img {
          transition: 0.2s;
          width: 25%;
          object-fit: cover;
        }
      }
      &.description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .tkt-info {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        .img {
          width: 30px;
          height: 30px;
          position: relative;
          img {
            height: 100%;
            object-fit: cover;
          }

          span {
            background: var(--primary-color);
            color: white;
            padding: 1px 5px;
            border-radius: 15px;
            position: absolute;
            bottom: -5px;
            right: 0;
            font-size: 8px;
          }
        }
        .no-img {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: white;
          background: var(--primary-color);
        }
        .desc {
          display: flex;
          align-items: center;
          gap: 10px;

          .tck-desc {
            .name {
              &:first-letter {
                text-transform: uppercase;
              }
            }
            .time {
              color: #cad6e2;
              font-weight: 400;
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
  .options {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    user-select: none;
    .dropdown-options {
      top: 60%;
    }
  }

  .details {
    max-height: 1000px;
    visibility: visible;
    opacity: 1;
    background: #f2f5f8;
    padding: 10px 25px 25px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    overflow: auto;
    font-size: 1rem;
    font-weight: normal;

    &.open {
      animation: open 0.5s ease-in-out forwards;
    }

    &.close {
      animation: close 0.5s ease-in-out forwards;
    }

    .title {
      font-weight: 500;
      padding: 10px 0;
    }
  }
}

@keyframes open {
  0% {
    max-height: 0px;
    opacity: 0;
    margin: 0;
  }
  100% {
    max-height: 1000px;
    opacity: 1;
    margin: 0 0 25px;
  }
}
@keyframes close {
  0% {
    max-height: 1000px;
    opacity: 1;
    margin: 0 0 25px;
  }
  100% {
    max-height: 0px;
    opacity: 0;
    margin: 0;
  }
}
</style>
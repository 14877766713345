<template>
    <div class="ticket">
        <div ref="tcktinfo" class="info">
            <div class="item large" @click="goToTicket">
                <div class="tkt-info">
                    <span class="no-img">{{ ticket.title.charAt(0) }}</span>
                    <div class="desc">
                        <div class="name">{{ticket.title}} #{{ticket.id}}</div>
                    </div>
                </div>
            </div>
            <div class="item">
                {{ticket.ticket_status?.status_details?.name || 'not defined'}}
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props: {
        ticket: {
            required: false,
            type: Object,
        },
    },
    methods: {
        goToTicket() {
            if (this.ticket.ticket_status.status_details.id === 5) this.$router.push({path : `/remote-support/history/${this.ticket.id}`})
            else this.$router.push({path : `/remote-support/tickets/${this.ticket.id}`})
        },
    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
.overlay {
    width: 100%;
    height: 80px;
    z-index: 2;
    background: rgba(0,0,0,0.3);
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #F2F5F8;

    .bar {
        width: 15%;
        height: 30px;
        background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
        background-size: 400% 400%;
        animation: load 1s ease infinite;
        border-radius: 30px;

        &.large {
            width: 20%;
            background: none;
            animation: unset;
            border-radius: none;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

            .round{
                width: 30px;
                height: 30px;
                background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
                background-size: 400% 400%;
                animation: load 0.5s ease infinite;
                border-radius: 50%;
            }

            .txt{
                display: flex;
                flex-direction: column;
                width: 85%;
                gap: 2px;

                .top{
                    height: 17px;
                }
                .bottom{
                    height: 11px;
                }
                div{
                    background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
                    background-size: 400% 400%;
                    animation: load 0.5s ease infinite;
                    border-radius: 30px;
                }
            }
        }
    }
}
.ticket{
    width: 100%;
    height: 80px;
    display: flex;
    padding: 0 25px;
    background: white;
    border-bottom: solid 1px #F2F5F8;
    cursor: pointer;

    &.notAssigned{
        background: $grey-opacity;
    }

    .info{
        width: 98%;
        display: flex;
        .item {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            font-weight: 600;

            &:nth-child(1) {
                @media only screen and (max-width: 768px) {
                    width: 100% !important;
                }
            }
            &:not(:nth-child(1)) {
                @media only screen and (max-width: 768px) {
                    display: none;
                }
            }

            .tkt-info {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 10px;

                .img{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;

                    img{
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .no-img{
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    color: white;
                    background: var(--primary-color);
                }

                .desc{
                    display: flex;
                    flex-direction: column;

                    .name {
                        &:first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}
</style>
<template>
  <template v-if="faqs.faqs.length">
    <template template v-for="(faq, index) in faqs.faqs" :key="index">
      <FAQ :faq="faq" />
    </template>
  </template>
  <template v-if="faqs.category && faqs.category.category_details.faqs.length">
    <template
      template
      v-for="(cat_faq, index) in faqs.category.category_details.faqs"
      :key="index"
    >
      <FAQ :faq="cat_faq" />
    </template>
  </template>
  <template
    v-if="!faqs.faqs.length && !faqs.category.category_details.faqs.length"
  >
    <span class="empty"> There are currently no FAQs. </span>
  </template>
</template>

<script>
import FAQ from "./FAQ.vue";
export default {
  props: {
    faqs: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      edit: false,
      faqSrch: "",
      details: false,
      open: false,
      show: false,
      editFaq: false,
    };
  },

  components: {
    FAQ,
  },

  watch: {
    details(val) {
      if (val) this.show = true;
      else {
        setTimeout(() => {
          this.show = false;
        }, 500);
      }
    },
  },

  methods: {
    closeOptions() {
      this.edit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-weight: 500;
}
</style>
<template>
    <div class="create-btn" @click="create = true">
      <span>Create New</span>
    </div>
    <div class="table-head">
      <div class="main-items">
        <span>Ticket</span>
        <span>Status</span>
      </div>
    </div>
    <template v-if="filteredTickets.length">
      <template v-for="(entry, index) in filteredTickets" :key="index">
        <ticket :ticket="entry" />
      </template>
    </template>
    <template v-else>
      <span class="empty">
          There are currently no tickets for this product.
      </span>
    </template>
    <create v-if="create" :items="toCreate" :response="response" @data="createNew" @close="create = false" />
</template>

<script>
import Ticket from './Ticket.vue'
import Create from '@/components/Create/Index.vue'
import axiosInstance from "@/services/AxiosTokenInstance";

export default {
    props: {
        tickets: {
            required: false,
            type: Object,
        },
    },

    data() {
      return {
        create: false,
        user: localStorage.getItem("U_P")
          ? JSON.parse(localStorage.getItem("U_P"))
          : {},
        response: [],
        filteredTickets: this.tickets.tickets
      }
    },

    computed: {
      toCreate() {
        return [
          {
            type: "text",
            name: "title",
            label: "Title",
            required: true
          },
          {
            type: "textarea",
            name: "description",
            label: "Description",
            required: true
          }
        ];
      },
    },

    components:{
        Ticket,
        Create
    },

    methods: {
      async createNew(data) {
        const org = JSON.parse(localStorage.getItem("organization"));

        const toSend = {
          id_organization: org.id,
          title: data.title,
          description: data.description,
          id_project: this.tickets.site.site_details.id,
          id_product: this.tickets.id,
          id_user: this.user.id,
        };

        const response = await axiosInstance.post(`add-ticket`, toSend);
        if (response.status === 200) {
          this.emitter.emit("create-room", {
            type: "ticket",
            ticketId: response.data.data,
            organizationId: org.id,
          });
          this.emitter.emit("alert", response.data.message);
          this.response = [true, true]
        }
        else {
          this.response = [true, false]
          this.emitter.emit("alert", "Something went wrong");
        }
      }
    }
};
</script>

<style lang="scss" scoped>
    .table-head {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 10px 25px;
      box-shadow: 0px 1px 6px #CAD6E280;
      border-bottom: solid 1px #F2F5F8;
      background: white;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      .main-items {
        width: 98%;
        display: flex;
        span {
          width: 100%;
          display: flex;
          font-size: 0.8rem;
          font-weight: 500;

            &:nth-child(1) {
                @media only screen and (max-width: 768px) {
                    width: 100% !important;
                }
            }
            &:not(:nth-child(1)) {
                @media only screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
      }
    }
  .empty{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    font-weight: 500;
  }
  .create-btn {
      position: absolute;
      padding: 10px 25px;
      top: -50px;
      right: 0px;
      background: var(--primary-color);
      color: white;
      border-radius: 30px;
      cursor: pointer;
      user-select: none;
      transition: 0.2s;

        @media only screen and (max-width: 1200px) {
            top: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 0px;
        }

      &:hover {
          background: var(--primary-hover);
      }
  }


</style>
<template>
  <div class="popup_overlay">
    <div v-if="!next" class="popup">
      <div class="title">Delete {{ category.name }}</div>
      <div class="warning">
        Deleting a category requires you to re-assign the products associated to
        another category.<br /><br />
        In case you have no category to re-assign the products to we advise you
        to create an unused category to do so.
      </div>
      <div class="buttons">
        <button class="delete" @click="next = true">delete</button>
        <button @click="$emit('close')">cancel</button>
      </div>
    </div>
    <div v-else class="popup">
      <div class="title">Delete {{ category.name }}</div>
      <div class="warning">
        <div class="item">
          <label>Select Category to re-assign products:</label>
          <form class="app-cover">
            <div id="select-box">
              <input
                type="checkbox"
                id="options-view-button"
                @click="handleClicks"
                @change="handleSel"
              />
              <div id="select-button" class="brd">
                <div id="selected-value">
                  <span class="sel">
                    <template v-if="Object.keys(selected).length">
                      <img
                        v-if="selected.images.length"
                        :src="
                          imgURL +
                          selected.images[0].image
                        "
                        class="sel-img"
                      />
                      <span class="sel-name">{{ selected.name }}</span>
                    </template>
                    <template v-else>Select category</template>
                  </span>
                </div>
                <div class="arrow">
                  <img
                    src="/assets/images/left-arrow.svg"
                    alt="close_chevron"
                  />
                </div>
              </div>
              <div class="options">
                <div
                  class="option"
                  v-for="(option, index) in categories"
                  :key="index"
                  @click="selectOption(option)"
                >
                  <img
                    v-if="option.images.length"
                    :src="
                      imgURL + option.images[0].image
                    "
                  />
                  <span class="label">{{ option.name }}</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="buttons">
        <button class="delete" @click="submitData" :disabled="!Object.keys(selected).length">confirm</button>
        <button @click="$emit('close')">cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
export default {
  props: {
    category: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      next: false,
      categories: [],
      selected: {},
      imgURL: process.env.VUE_APP_DO_SPACES
    };
  },
  methods: {
    async getData() {
      let orgDataString = localStorage.getItem("organization");
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let response = await axiosInstance.get(`category?org_id=${orgData.id}`);
        let responseData = response.data.data;
        this.categories.push(...responseData.data);

        if (responseData.current_page < responseData.last_page) {
          for (let i = 0; i <= responseData.last_page; i++) {
            let res = await axiosInstance.get(
              `category?org_id=${orgData.id}&page=${i}`
            );
            let resData = res.data.data;
            this.categories.push(...resData.data);
          }
        }

        this.categories.splice(
          this.categories.map((el) => el.id).indexOf(this.category.id),
          1
        );
      }
    },
    selectOption(option) {
      this.selected = option;
      document.getElementById("options-view-button").checked = false;
    },

    submitData() {
      this.$emit("submitData", this.selected);
      this.next = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.popup_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup {
    background: white;
    width: 400px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 1px 8px #142e6e1a;
    .title {
      display: flex;
      justify-content: center;
      padding: 10px;
      border-bottom: 1px solid #0510244d;
      font-weight: 600;
      font-size: 1.2rem;
      box-shadow: 0px 1px 8px #142e6e1a;
    }
    .warning {
      display: flex;
      justify-content: center;
      padding: 10px 25px;
      font-weight: 500;
      text-align: center;

      .item {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      gap: 20px;
      padding: 10px 25px;
      button {
        cursor: pointer;
        border-radius: 5px;
        border: solid 1px rgba(20, 45, 110, 0.322);
        color: rgba(20, 45, 110, 0.322);
        padding: 10px 25px;
        text-transform: capitalize;
        font-weight: 500;
        transition: 0.2s;

        &:hover {
          color: white;
          background: rgba(20, 45, 110, 0.322);
          border: solid 1px transparent;
        }

        &.delete {
          border: solid 1px #fb4b5a;
          color: #fb4b5a;
          &:hover {
            color: white;
            background: #fb4b5a;
            border: solid 1px transparent;
          }
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.options {
  display: none;
  position: absolute;
  left: 0;
  height: fit-content;
  max-height: 150px;
  overflow: auto;
  border-radius: 5px;
  width: 100%;
  background: white;
}

.option {
  position: relative;
  line-height: 1;
  transition: 0.3s ease all;
  z-index: 2;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  display: none;
  min-width: 150px;
}

.option img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
}

.option,
.label {
  font-size: 16px;
}

#options-view-button:checked ~ .options {
  display: block;
}

#options-view-button:checked ~ .options .option {
  display: flex;
  background: white;
}

#options-view-button:checked ~ .options .option:hover {
  background: #f2f5f8;
  border-radius: 5px;
}

#select-button {
  border-radius: 5px;
  border: solid 1px #cad6e2;
  cursor: pointer;
  position: relative;
  padding: 5px 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#select-button .arrow {
  width: 10px;
}

#select-button .arrow img {
  opacity: 0.5;
  width: 100%;
  transform: rotate(270deg);
  transition: 0.2s;
}

#options-view-button:checked ~ #select-button .arrow img {
  transform: rotate(90deg);
}

#select-box {
  position: relative;
}

#options-view-button {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 5;
}

#selected-value {
  font-size: 16px;
  line-height: 1;
  margin-right: 26px;
}

#options-view-button:checked ~ .options {
  border: 1px solid #e2eded;
  border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
  z-index: 2;
}

.label {
  display: none;
  padding: 0;
}

#options-view-button:checked ~ .options .label {
  display: block;
}

.s-c {
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
}

.s-c.top {
  top: 0;
}

.s-c.bottom {
  bottom: 0;
}

input[type="radio"] {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 50%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.s-c:hover ~ i {
  color: #fff;
  opacity: 0;
}

.s-c:hover {
  height: 100%;
  z-index: 1;
}

.label {
  transition: 0.3s ease all;
}

.opt-val {
  position: absolute;
  left: 14px;
  width: 217px;
  height: 21px;
  opacity: 0;
  background-color: #fff;
  transform: scale(0);
}

.option input[type="radio"]:checked ~ .opt-val {
  opacity: 1;
  transform: scale(1);
}

.option input[type="radio"]:checked ~ img {
  top: 0;
  bottom: auto;
  opacity: 1;
  animation: unset;
}

.option input[type="radio"]:checked ~ img,
.option input[type="radio"]:checked ~ .label {
  color: #fff;
}

.option input[type="radio"]:checked ~ .label:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

#options-view-button:not(:checked)
  ~ .options
  .option
  input[type="radio"]:checked
  ~ .opt-val {
  top: -30px;
}
#options-view-button:not(:checked) ~ .options {
  z-index: 1;
}

.option input[type="radio"]:checked ~ .label:before {
  background-color: #000;
  border-radius: 4px;
}

#option-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 40px;
  transition: 0.3s ease all;
  z-index: 1;
  display: none;
}

#options-view-button:checked ~ .options #option-bg {
  display: block;
}

.optionhover ~ #option-bg {
  top: 0;
  background-color: rgba(97, 97, 97, 0.692);
  border-radius: 4px 4px 0 0;
}

#ytd-url {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px 14px;
  margin: 20px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  background-color: #143240;
  border-radius: 4px;
  box-shadow: 0 10px 20px -5px rgba(20, 50, 64, 0.76);
}

.sel {
  display: flex;
  align-items: center;
  gap: 5px;
}
.sel .sel-img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
}
.submit {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.submit span {
  padding: 10px 25px;
  border-radius: 30px;
  background: var(--primary-color);
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
}
.submit span:hover {
  background: #1d4ec0;
}
</style>
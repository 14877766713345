<template>
  <div class="document">
    <div ref="tcktinfo" class="info">
      <div class="item large" @click="preview = true">
        {{ guide.title }}
      </div>
    </div>
  </div>
  <preview
    v-if="preview"
    :type="'step-desktop'"
    :info="{ id: guide.id }"
    @close="preview = false"
  />
</template>

<script>
import Preview from "@/components/previews/Preview.vue";
export default {
  props: {
    guide: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      preview: false,
    };
  },
  components: {
    Preview,
  },
  methods: {
    closeOptions() {
      this.edit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
input[type="checkbox"] {
  opacity: 0.5;
}
.overlay {
  width: 100%;
  height: 80px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #f2f5f8;
  .bar {
    width: 15%;
    height: 30px;
    background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
    background-size: 400% 400%;
    animation: load 1s ease infinite;
    border-radius: 30px;
    &.large {
      width: 20%;
      background: none;
      animation: unset;
      border-radius: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      .round {
        width: 30px;
        height: 30px;
        background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
        background-size: 400% 400%;
        animation: load 0.5s ease infinite;
        border-radius: 50%;
      }
      .txt {
        display: flex;
        flex-direction: column;
        width: 85%;
        gap: 2px;

        div {
          background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
          background-size: 400% 400%;
          animation: load 0.5s ease infinite;
          border-radius: 30px;
        }
        .top {
          height: 17px;
        }
        .bottom {
          height: 11px;
        }
      }
    }
  }
}
.document {
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0 25px;
  background: white;
  border-bottom: solid 1px #f2f5f8;
  cursor: pointer;

  &.notsite {
    background: var(--primary-hover);
  }
  .info {
    width: 98%;
    display: flex;
    .item {
      width: calc(100% / 2);
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      &.description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .tkt-info {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        .img {
          width: 30px;
          height: 30px;
          position: relative;
          img {
            height: 100%;
            object-fit: cover;
          }

          span {
            background: var(--primary-color);
            color: white;
            padding: 1px 5px;
            border-radius: 15px;
            position: absolute;
            bottom: -5px;
            right: 0;
            font-size: 8px;
            text-transform: lowercase;
          }
        }
        .img-edit {
          width: 30px;
          height: 30px;
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          img {
            height: 100%;
            object-fit: cover;
          }

          span {
            background: var(--primary-color);
            color: white;
            padding: 1px 5px;
            border-radius: 15px;
            position: absolute;
            bottom: -5px;
            right: 0;
            font-size: 8px;
            text-transform: lowercase;
          }
        }
        .no-img {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: white;
          background: var(--primary-color);
        }
        .desc {
          display: flex;
          align-items: center;
          gap: 10px;

          .tck-desc {
            .name {
              &:first-letter {
                text-transform: uppercase;
              }
            }
            .time {
              color: #cad6e2;
              font-weight: 400;
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
  .options {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    user-select: none;
    .dropdown-options {
      top: 60%;
    }
  }
}
</style>
<template>
  <div class="document">
    <div ref="tcktinfo" class="info">
      <div class="item large" @click="goToDocument">
        <div class="tkt-info">
          <div class="desc">
            <div class="img">
              <template v-if="document.files.length <= 1">
                <img src="/assets/images/doc-icon.svg" alt="file_icon" />
                <span
                  >.{{
                    document.file_type || document.files.length
                      ? document.files[0].file_type
                      : "null"
                  }}</span
                >
              </template>
              <template v-else>
                <img src="/assets/images/folder_icon.svg" alt="folder_icon" />
                <span class="folder">folder</span>
              </template>
            </div>
            <div class="tck-desc">
              <div class="name">{{ document.name }}</div>
              <div class="user">
                <span class="time" v-if="document.files.length <= 1"
                  >File size: {{ document.file_size }}</span
                >
                <span class="time" v-else
                  >Files inside: {{ document.files.length }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <template v-if="document.category_id">Category</template>
        <template v-else-if="document.product_id">Product</template>
        <template v-else>undefined</template>
      </div>
      <div class="item">
        <template v-if="document.updated_at">
          <span>{{ timeSince }}</span>
        </template>
        <template v-else> undefined </template>
      </div>
    </div>
    <preview
      v-if="folder"
      :type="'folder'"
      :info="document"
      @close="folder = false"
    />
  </div>
</template>

<script>
import Preview from "@/components/previews/Preview.vue";
import { mapGetters } from "vuex";
import api from "@/mixins/optionsApi";
export default {
  props: {
    type: {
      required: false,
      type: String,
    },
    document: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      edit: false,
      tosites: false,
      documentSrch: "",
      searchResult: [],
      toTitle: "Add User",
      editDoc: false,
      folder: false,
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
    };
  },
  components: {
    Preview,
  },

  mixins: [api],

  computed: {
    ...mapGetters({
      sites: "site/getSites",
    }),
    toEdit() {
      return [
        {
          type: "text",
          label: "Name",
          value: this.document.name,
        },
        {
          type: "choice",
          label: "Belongs to",
          value: [this.type, this.document.category || this.document.product],
          options: [
            {
              label: "category",
              options: this.api("categories"),
            },
            {
              label: "product",
              options: this.api("products"),
            },
          ],
        },
        {
          type: "select",
          label: "Status",
          options: [
            { id: 0, name: "Inactive" },
            { id: 1, name: "Active" },
          ],
          value: {
            id: this.document.status === 1 ? 1 : 2,
            name: this.document.status === 1 ? "Active" : "Inactive",
          },
        },
      ];
    },
    timeSince() {
      const last_updated = Date.parse(this.document.updated_at);
      const seconds = Math.floor((new Date() - last_updated) / 1000);
      let interval = seconds / 31536000;
      if (interval > 1) {
        return `at ${this.document.updated_at.split("T")[0]}`;
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return `at ${this.document.updated_at.split("T")[0]}`;
      }
      interval = seconds / 86400;
      if (interval > 1) {
        if (Math.floor(interval) > 5)
          return `at ${this.document.updated_at.split("T")[0]}`;
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "day ago" : "days ago"
        }`;
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "hour ago" : "hours ago"
        }`;
      }
      interval = seconds / 60;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "minute ago" : "minutes ago"
        }`;
      }
      return `${Math.floor(seconds)} ${
        Math.floor(seconds) === 1 ? "second ago" : "seconds ago"
      }`;
    },
  },
  methods: {
    closeOptions() {
      this.edit = false;
    },

    async goToDocument() {
      if (this.document.files.length > 1) {
        this.folder = true;
      } else {
        if (this.document.file) window.open(this.imgURL + this.document.file);
        else window.open(this.imgURL + this.document.files[0].file);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
input[type="checkbox"] {
  opacity: 0.5;
}
.overlay {
  width: 100%;
  height: 80px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #f2f5f8;
  .bar {
    width: 15%;
    height: 30px;
    background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
    background-size: 400% 400%;
    animation: load 1s ease infinite;
    border-radius: 30px;
    &.large {
      width: 20%;
      background: none;
      animation: unset;
      border-radius: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      .round {
        width: 30px;
        height: 30px;
        background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
        background-size: 400% 400%;
        animation: load 0.5s ease infinite;
        border-radius: 50%;
      }
      .txt {
        display: flex;
        flex-direction: column;
        width: 85%;
        gap: 2px;

        div {
          background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
          background-size: 400% 400%;
          animation: load 0.5s ease infinite;
          border-radius: 30px;
        }
        .top {
          height: 17px;
        }
        .bottom {
          height: 11px;
        }
      }
    }
  }
}
.document {
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0 25px;
  background: white;
  border-bottom: solid 1px #f2f5f8;
  cursor: pointer;

  &.notsite {
    background: var(--primary-hover);
  }
  .info {
    width: 98%;
    display: flex;
    .item {
      width: calc(100% / 3);
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;

      &:nth-child(1) {
            @media only screen and (max-width: 768px) {
                width: 100% !important;
            }
        }
        &:not(:nth-child(1)) {
            @media only screen and (max-width: 768px) {
                display: none;
            }
        }
      &.description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .tkt-info {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        .img {
          width: 30px;
          height: 30px;
          position: relative;
          img {
            height: 100%;
          }

          span {
            background: var(--primary-color);
            color: white;
            padding: 1px 5px;
            border-radius: 15px;
            position: absolute;
            bottom: -5px;
            right: 0;
            font-size: 8px;
            text-transform: lowercase;

            &.folder {
              right: -3px;
            }
          }
        }
        .img-edit {
          width: 30px;
          height: 30px;
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          img {
            height: 100%;
            object-fit: cover;
          }

          span {
            background: var(--primary-color);
            color: white;
            padding: 1px 5px;
            border-radius: 15px;
            position: absolute;
            bottom: -5px;
            right: 0;
            font-size: 8px;
            text-transform: lowercase;
          }
        }
        .no-img {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: white;
          background: var(--primary-color);
        }
        .desc {
          display: flex;
          align-items: center;
          gap: 10px;

          .tck-desc {
            .name {
              &:first-letter {
                text-transform: uppercase;
              }
            }
            .time {
              color: #cad6e2;
              font-weight: 400;
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
  .options {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    user-select: none;
    .dropdown-options {
      top: 60%;
    }
  }
}
</style>
<template>
  <div class="goback" @click="goback">
    <img src="/assets/images/left-arrow.svg" alt="close_chevron" />
    <span>go back</span>
  </div>
  <div class="contentTitle">
    <h3>{{ entries.name || "Equipment" }}</h3>
  </div>
  <div class="contentMenu">
    <ul class="navbar-nav">
      <li
        class="nav-item"
        :class="{ active: isActive === 'info' }"
        @click="isActive = 'info'"
      >
        <a class="nav-link" href="#">Info</a>
      </li>
      <li
        class="nav-item"
        :class="{ active: isActive === 'tickets' }"
        @click="isActive = 'tickets'"
      >
        <a class="nav-link" href="#">Tickets</a>
      </li>
      <li
        class="nav-item"
        :class="{ active: isActive === 'docs' }"
        @click="isActive = 'docs'"
      >
        <a class="nav-link" href="#">Relevant Docs</a>
      </li>
      <li
        class="nav-item"
        :class="{ active: isActive === 'faqs' }"
        @click="isActive = 'faqs'"
      >
        <a class="nav-link" href="#">Relevant FAQs</a>
      </li>
      <li
        class="nav-item"
        :class="{ active: isActive === 'guides' }"
        @click="isActive = 'guides'"
      >
        <a class="nav-link" href="#">Relevant Guides</a>
      </li>
    </ul>
  </div>
  <section class="users-list">
    <template v-if="Object.keys(entries).length">
      <info v-if="isActive === 'info'" :info="entries" />
      <docs v-if="isActive === 'docs'" :docs="entries" />
      <faqs v-if="isActive === 'faqs'" :faqs="entries" />
      <guides v-if="isActive === 'guides'" :guides="entries" />
      <tickets v-if="isActive === 'tickets'" :tickets="entries" />
    </template>
    <template v-else>
      <div class="loading">
        <span class="loader"></span>
      </div>
    </template>
  </section>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import Info from "./Info/Index.vue";
import Docs from "./Docs/Index.vue";
import Faqs from "./Faqs/Index.vue";
import Guides from "./Guides/Index.vue";
import Tickets from "./Tickets/Index.vue";

export default {
  title() {
    return `Remote Assistance | Equipment Details`;
  },
  props: {
    tickets: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      entries: {},
      imgURL: process.env.VUE_APP_DO_SPACES,
      isActive: "info",
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
    };
  },

  components: {
    Info,
    Docs,
    Faqs,
    Guides,
    Tickets,
  },

  methods: {
    async getData() {
      const org = JSON.parse(localStorage.getItem("organization"));
      const client_id = this.client ? this.client.id : "";
      let response = await axiosInstance.get(
        `product/${this.$route.params.prodid}?org_id=${org.id}&clientId=${client_id}`
      );
      let responseData = response.data.data;
      this.entries = responseData;
    },

    goback() {
      this.$router.push({ path: "/products/equipments" });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.goback {
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;
  cursor: pointer;
  transition: 0.2s;
  img {
    opacity: 0.5;
    width: 10px;
  }
  span {
    opacity: 0.5;
    transition: 0.2s;
    &:hover {
      opacity: 1;
      color: var(--primary-color);
    }
  }
}

.contentMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;

    @media only screen and (max-width: 1200px) {
        overflow: auto;
    }
  .search-filters {
    display: flex;
    gap: 20px;
    align-items: center;
    .searchPan {
      position: relative;
      display: flex;
      align-items: center;
      .searchIcon {
        position: absolute;
        left: 10px;
        color: #cad6e2;
      }
      .form-control {
        padding: 0 30px;
        &::placeholder {
          color: #cad6e2;
        }
      }
    }
  }
}

.users-list {
  height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
}

.loading {
  height: 500px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<template>
        <div class="table-head">
          <div class="main-items">
            <span>Title</span>
          </div>
        </div>
        <template v-if="guides.guides.length">
          <template v-for="(entry, index) in guides.guides" :key="index">
            <guide :guide="entry" />
          </template>
        </template>
        <template v-if="guides.category && guides.category.category_details.guides.length">
          <template v-for="(entry, index) in guides.category.category_details.guides" :key="index">
            <guide :guide="entry" />
          </template>
        </template>
        <template v-else>
          <span class="empty">
              There are currently no guides for this equipment.
          </span>
        </template>
</template>

<script>
import Guide from './Guide.vue'

export default {
    props: {
        guides: {
            required: false,
            type: Object,
        },
    },

    components:{
        Guide
    },
};
</script>

<style lang="scss" scoped>
    .table-head {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 10px 25px;
      box-shadow: 0px 1px 6px #CAD6E280;
      border-bottom: solid 1px #F2F5F8;
      background: white;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      .main-items {
        width: 98%;
        display: flex;
        span {
        width: calc(100% / 2);
        display: flex;
        font-size: 0.8rem;
        font-weight: 500;
        }
      }
    }
  .empty{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    font-weight: 500;
  }


</style>
<template>
  <div class="equipment-info">
    <div class="image">
      <img
        :src="
          info.product_images.length
            ? imgURL + info.product_images[0].image
            : info.category.category_details?.images.length
            ? imgURL + info.category.category_details.images[0].image
            : ''
        "
        alt=""
      />
    </div>
    <div class="info">
      <div class="title">{{ info.name }} #{{ info.serial_no }}</div>
      <div class="details">
        <div class="item">
          <div class="sub">Client</div>
          <div class="desc">
            <span>{{
              info.site?.site_details?.client?.client_details?.name ||
              "undefined"
            }}</span>
          </div>
        </div>
        <div class="item">
          <div class="sub">Site</div>
          <div class="desc">
            <span>{{ info.site?.site_details?.name || "undefined" }}</span>
          </div>
        </div>
        <div class="item">
          <div class="sub">Warranty</div>
          <div class="desc">
            Expires at {{ info.warranty_end_date || "undefined" }}
          </div>
        </div>
        <div class="item">
          <div class="sub">Maintenance</div>
          <div class="desc">
            Valid until {{ info.maintenance || "undefined" }}
          </div>
        </div>
        <div class="item">
          <div class="sub">Ref</div>
          <div class="desc">
            {{ info.reference || info.product_number || "undefined" }}
          </div>
        </div>
      </div>
    </div>
    <div class="qr-code" @click="showQr = true">
      <img
        :src="'data:image/jpeg;base64,' + info.barcode"
        alt="QR Code"
        title="Click to download PDF version"
      />
    </div>
  </div>
  <qr-modal
    v-if="showQr"
    :info="{
      name: info.name,
      serial_no: info.serial_no,
      barcode: info.barcode,
    }"
    @close="showQr = false"
  />
</template>

<script>
import qrModal from "@/components/Ui/General/QrCode.vue";
export default {
  props: {
    info: {
      required: false,
      type: Object,
    },
  },

  data() {
    return {
      showQr: false,
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },

  components: {
    qrModal,
  },
};
</script>

<style lang="scss" scoped>
.equipment-info {
    width: 100%;
    border-radius: 5px;
    background: white;
    box-shadow: 0px 1px 6px #cad6e280;
    padding: 25px;
    display: flex;
    gap: 25px;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    .image {
        width: 10%;

        @media only screen and (max-width: 768px) {
            width: 100%;
            max-height: 400px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .info {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 25px;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        
        .title {
            font-weight: bold;
            font-size: 1.2rem;
        }
        
        .details {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            .desc {
                font-weight: 500;
            }
        }
    }

    .qr-code {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
        width: 100%;
        }
    }
}
</style>
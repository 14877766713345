<template>
  <List
    :title="'Category'"
    :entries="bodyItems"
    :headItems="headItems"
    :hasCheck="false"
    :canCreate="canCreate"
    :loaded="loaded"
    :toFilter="toFilter"
    :tabFilters="tabFilters"
    :isActive="isActive"
    :total="total"
    :perPage="perPage"
    :currentPage="currentPage"
    @optionSel="handleOption"
    @create="create = true"
    @filters="filterByItem"
    @search="handleSearch"
  />
  <Pagination
    :entries="entries"
    :currentPage="currentPage"
    :total="total"
    :perPage="perPage"
    :pages="pages"
    @goToFirst="currentPage = 1"
    @goToLast="currentPage = pages"
    @goToPage="handlePage"
    @changePage="handlePageChange"
  />
  <see-modal
    v-if="see"
    :qr="entries.find((el) => el.id === selItem).barcode"
    :title="`Category Card`"
    :items="toSee"
    @close="see = false"
  />
  <edit-modal
    v-if="editItem"
    :title="`Edit Category`"
    :items="toEdit"
    :response="response"
    @close=";(editItem = false), (response = [])"
    @submitData="editCategory"
  />
  <Create
    v-if="create"
    :items="toCreate"
    :response="response"
    @data="handleCreateData"
    @close=";(create = false), (response = [])"
  />
  <delete
    :category="entries.find((el) => el.id === selItem)"
    v-if="del"
    @close="closeReplace"
    @submitData="replaceCategory"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import List from "@/components/List/Index.vue";
import Pagination from "@/components/List/Pagination.vue";
import timeSince from "@/mixins/timeSince";
import isMobile from "@/mixins/isMobile";
import SeeModal from "@/components/Ui/CRUD/See.vue";
import EditModal from "@/components/Ui/CRUD/Edit.vue";
import Create from "@/components/Create/Index.vue";
import Delete from "./Delete.vue";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Categories",
      entries: [],
      currentPage: 1,
      filteredEntries: [],
      total: 0,
      pages: 0,
      perPage: 10,
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      filter: [],
      canCreate: [4, 5, 6, 7, 8],
      headItems: ["Name", "Description", "Status", "Last Edit"],
      bodyItems: [],
      editOptions: ["see", "edit"],
      search_key: "",
      hasOptions: true,
      see: false,
      editItem: false,
      create: false,
      del: false,
      selItem: 0,
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
      tabFilters: [
        {
          name: "All Categories",
          filter: "",
          isActive: true,
        },
      ],
      isActive: 0,
      response: [],
    };
  },

  provide() {
    return {
      edit: this.editOptions,
      hasOptions: this.hasOptions,
    };
  },

  components: {
    List,
    Pagination,
    SeeModal,
    EditModal,
    Delete,
    Create,
  },

  mixins: [timeSince, isMobile],

  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.getData(val);
      },
    },

    filter() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            return
        }
        this.getData(1);
    },

    entries() {
      this.fillBodyItems();
    },

    search_key() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            return
        }
        this.getData(1);
    },
  },

  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },
    toFilter() {
      return [
        {
          name: "status",
          options: [
            { id: 1, name: "Active" },
            { id: 2, name: "Inactive" },
          ],
        },
      ];
    },
    toCreate() {
      return [
        {
          type: "image",
          name: "image",
          label: "Image",
          required: false,
        },
        {
          type: "text",
          name: "name",
          label: "Name",
          required: true,
        },
        {
          type: "textarea",
          name: "description",
          label: "Description",
          required: false,
        },
      ];
    },
    toSee() {
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      if (Object.keys(item).length) {
        return [
          {
            title: "Header",
            image: item.images.length
              ? this.imgURL + item.images[0].image
              : null,
            value: item.name,
            id: item.id,
          },
          {
            title: "Description",
            value: item.description,
          },
          {
            title: "Last Update",
            value: this.timeSince(item.updated_at),
          },
          {
            title: "Status",
            value: item.status === 1 ? "Active" : "Inactive",
          },
        ];
      }
      return [];
    },
    toEdit() {
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      
      if (Object.keys(item).length) {
        return [
            {
            type: "image",
            label: "Image",
            value: item.images.length ? this.imgURL + item.images[item.images.length-1].image : null,
            },
          {
            type: "text",
            label: "Name",
            value: item.name,
          },
          {
            type: "textarea",
            label: "Description",
            value: item.description,
            required: true,
          },
          {
            type: "select",
            label: "Status",
            options: [
              { id: 0, name: "Inactive" },
              { id: 1, name: "Active" },
            ],
            value: {
              id: item.status === 1 ? 1 : 2,
              name: item.status === 1 ? "Active" : "Inactive",
            },
          },
        ];
      }
      return [];
    },
  },
  methods: {
    handlePageChange(to) {
      switch (to) {
        case "less":
          this.currentPage -= 1;
          break;
        case "more":
          this.currentPage += 1;
          break;
      }
    },

    handlePage(to) {
        this.currentPage = to
    },

    fillBodyItems() {
      this.bodyItems = this.entries.map((item) => {
        return [
          {
            template: "image",
            id: item.id,
            data: {
              image: item.images.length
                ? this.imgURL + item.images[0].image
                : null,
              title: item.name,
              description: [
                {
                  light: false,
                  text: `created ${this.timeSince(item.created_at)}`,
                },
              ],
            },
          },
          {
            template: "normal",
            data: item.description || "undefined",
          },
          {
            template: "normal",
            data: item.status === 1 ? "Active" : "Inactive",
          },
          {
            template: "image",
            data: {
              image: item.user ? this.imgURL + item.user.avatar : null,
              title: item.user ? item.user.name : "undefined",
              description: [
                {
                  light: false,
                  text: this.timeSince(item.updated_at),
                },
              ],
            },
          },
        ];
      });
    },

    async getData(value) {
      this.loaded = false;
      let orgDataString = localStorage.getItem("organization");
      this.entries = [];
      this.perPage = 0;
      this.total = 0;
      this.pages = 0;
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let response;
        if (!this.filter.length)
          response = await axiosInstance
            .get(
              `category?org_id=${orgData.id}&searchKey=${this.search_key}&page=${value}`
            )
            .finally(() => (this.loaded = true));
        else
          response = await axiosInstance
            .get(
              `category?org_id=${orgData.id}&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&page=${value}`
            )
            .finally(() => (this.loaded = true));
        let responseData = response.data.data;
        this.entries = responseData.data;
        this.perPage = responseData.per_page;
        this.total = responseData.total;
        this.pages = responseData.last_page;
      }
    },

    filterByItem(data) {
      switch (data[0]) {
        case "status":
          this.filter = ["status_id", data[1]];
          break;
        case "remove":
          this.filter = [];
          break;
      }
    },

    async handleCreateData(data) {
      if (!data.name) return this.handleAlert("Name is mandatory!");

      const org = JSON.parse(localStorage.getItem("organization"));

      let toSend = new FormData();

      toSend.append("org_id", org.id);
      toSend.append("name", data.name);
      if (data.description)
        toSend.append("description", data.description || "");
      if (data.image?.length) {
        for (var i = 0; i < data.image.length; i++) {
          toSend.append(`category_images[${i}]`, data.image[i].originalFile);
        }
      }

      const response = await axiosInstance.post(`category`, toSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

        if (response.status === 200) {
        this.response = [true, true];
        this.handleAlert(response.data.message);
        this.getData(1);
      } else {
        this.response = [true, false];
        this.handleAlert("Something went wrong");
      }
    },

    handleAlert(data) {
      this.emitter.emit("alert", data);
    },

    handleOption(data) {
      this.selItem = data[1];
      switch (data[0]) {
        case "see":
          this.see = true;
          break;
        case "edit":
          this.editItem = true;
          break;
        case "delete":
          this.deleteCategory();
          break;

        default:
          break;
      }
    },

    async editCategory(data) {
        const org = JSON.parse(localStorage.getItem("organization"));
        let toSend = new FormData();

        toSend.append("org_id", org.id);
        toSend.append("categoryId", this.selItem);
        toSend.append("name", data.Name);
        toSend.append("status", data.Status.id);

        if (data.Description) toSend.append("description", data.Description || "");

        if (data.Images?.length) {
            for (var i = 0; i < data.Images.length; i++) {
                toSend.append(`category_images[${i}]`, data.Images[i].originalFile);
            }
        }

        const res = await axiosInstance.post(`category`, toSend);

        if (res.status === 200) {
            this.handleAlert(res.data.message);
            this.getData(1);
            this.response = [true, true]
        } else {
            this.response = [true, false];
        }

        this.selItem = 0;
    },

    async deleteCategory() {
      this.del = true;
    },

    closeReplace() {
      this.del = false;
      this.selItem = 0;
    },

    async replaceCategory(data) {
      this.del = false;
      let orgData = JSON.parse(localStorage.getItem("organization"));
      const res = await axiosInstance.delete(
        `category/${this.selItem}?replacementId=${data.id}&org_id=${orgData.id}`
      );

      if (res.status === 200) {
        this.handleAlert(res.data.message);
        this.getData(1);
      }
      this.selItem = 0;
    },

    handleSearch(data) {
      this.search_key = data;
    },
  },
};
</script>
<template>
        <div class="table-head">
          <div class="main-items">
            <span>Name</span>
            <span>Belongs to</span>
            <span>Last Edit</span>
          </div>
        </div>
        <template v-if="docs.documents.length">
          <template v-for="(entry, index) in docs.documents" :key="index">
            <document :document="entry" />
          </template>
        </template>
        <template v-if="docs.category && docs.category.category_details.documents.length">
          <template v-for="(entry, index) in docs.category.category_details.documents" :key="index">
            <document :document="entry" />
          </template>
        </template>
        <template v-else-if="!docs.documents.length && (!docs.category || (docs.category && !docs.category.category_details.documents.length)) ">
          <span class="empty">
              There are currently no documents.
          </span>
        </template>
</template>

<script>
import Document from './Document.vue'

export default {
    props: {
        docs: {
            required: false,
            type: Object,
        },
    },

    components:{
        Document
    },
};
</script>

<style lang="scss" scoped>
    .table-head {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 10px 25px;
      box-shadow: 0px 1px 6px #CAD6E280;
      border-bottom: solid 1px #F2F5F8;
      background: white;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      .main-items {
        width: 98%;
        display: flex;
        span {
            width: calc(100% / 3);
            display: flex;
            font-size: 0.8rem;
            font-weight: 500;
        
            &:nth-child(1) {
                @media only screen and (max-width: 768px) {
                    width: 100% !important;
                }
            }
            &:not(:nth-child(1)) {
                @media only screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
      }
    }
  .empty{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    font-weight: 500;
  }


</style>